import React from "react"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ProjectListing from "../../components/ProjectListing"
import { graphql } from "gatsby"

export default function GamingRoleTemplate({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    let {title, field_years_worked, body, field_achievements, relationships:{ field_featured_image, field_projects }} = data.nodeOrganizationsWorkedFor
    const {} = data.nodeOrganizationsWorkedFor

    const CMS_BASE_URL = "https://cms.krestonshirley.com"

    let overview = body.value;
    let highlights = field_achievements.value;
    let projects = field_projects;
    console.log("FROM PROJECTS",projects);

    return (
        <Layout>
            <SEO title={title}/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        {/*    Overview */}
                        <h1>{title}</h1>
                        <span>Years Active: {field_years_worked.value} - {field_years_worked.end_value}</span>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        {/*    Image */}
                        <img src={CMS_BASE_URL + field_featured_image.uri.url} alt=""/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <h2>Overview</h2>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        {/*  Highlight Paragraph */}
                        <div dangerouslySetInnerHTML={{ __html: overview}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <h2>Accomplishments</h2>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        {/*  Highlight Paragraph */}

                        <div dangerouslySetInnerHTML={{ __html: highlights}}/>
                    </div>
                </div>
                <div className="row">
                    {/*    Add component with */}
                    <ProjectListing projectListing={field_projects}/>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    nodeOrganizationsWorkedFor(path: {alias: {eq: $path}}) {
      title
      body {
        value
      }
      field_achievements {
        value
      }
      field_years_worked {
        value(formatString: "YYYY")
        end_value(formatString: "YYYY")
      }
      relationships {
        field_featured_image {
          uri {
            url
          }
        }
        field_projects {
            title
            body {
                summary
            }
            path {
                alias
            }
            relationships {
                field_work_images {
                  ... on media__image {
                    relationships {
                      field_media_image {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
            }
        }
      }
    }
  }
`
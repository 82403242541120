import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"

function ProjectListing({projectListing}){

    console.log("From Project Component", projectListing);
    const CMS_BASE_URL = "https://cms.krestonshirley.com";


    return(
        <div className="row gaming-project-row">
            {/* Add Projects loop */}
            {projectListing.map( (project, i) => (
                    <div key={i} className="col-sm-12 col-md-4 gaming-project-work d-flex flex-column">
                        <Link to={project.path.alias}><img src={CMS_BASE_URL + project.relationships.field_work_images[1].relationships.field_media_image.uri.url} alt=""/></Link>
                        <h3>{project.title}</h3>
                        <p>{project.body != null ? project.body.summary : "Something there"}</p>
                        <Link className="btn btn-outline-secondary kes-button align-self-center align-self-end" to={project.path.alias}>Learn More</Link>
                    </div>
                )
            )}
        </div>
    );
}

export default ProjectListing